// Rotterdam specific style's

/************* COLORS *************/

$bgcolor: #fff;
$borderColor: #18933c;
$link: #fff;
$headingColor: #fff;
$headingBg: #18933c;
$button: $headingBg;
$buttonHover: #009ee0;
$someTextColor: #000;
$profileLinks: $borderColor;

$primaryColor: $button;

/************* LOGO *************/
$logoWidth: 200px;
$logoHeight: 100px;
$logoImage: image-url('whitelabels/logo-rotterdam.jpg'); 
/************* header *************/
$showHeaderLinks: true;

/************* AUTH *************/
$authBorderTop: 18px solid $headingBg;
$authBgColor: $borderColor;
$authTextColor: $link;

/************* CONTENT *************/
$contentPadding: 0;
$contentBg: #eeecf3;
$contentBgGenerator: transparent;
$contentBoxShadow: 0 1px 1px 1px #ccc;
$contentH1Padding: 10px;
$contentH1Color: $headingColor;
$contentH1Bg: $headingBg;

/************* FOOTER *************/
$footerShowHouses: false;
$footerColorBg: #dfe0e1;

/************* OTHER *************/
$mainBg: none;
$containerBg: $bgcolor;
$containerInnerSmallBg: none;
$containerInnerLargeBg: none;
$schetsBottomBg: none;
$schetsBottomBgGenerator: none;
$authTextColor: $link;

@import '../application';
